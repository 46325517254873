import { WhlPageType } from '@models/WhlPageType';

export default defineNuxtRouteMiddleware((to, from) => {
  if (!import.meta.client) return;

  let fromPageType = null;
  let toPageType = null;

  const fromPathSegments = from.path.split('/');
  const toPathSegments = to.path.split('/');

  // Check from-path
  if (fromPathSegments.includes(WhlPageType.Bookmarks)) {
    fromPageType = WhlPageType.Bookmarks;
  } else if (fromPathSegments.includes(WhlPageType.Overview)) {
    fromPageType = WhlPageType.Overview;
  } else if (fromPathSegments.includes(WhlPageType.List)) {
    fromPageType = WhlPageType.List;
  } else if (fromPathSegments.includes(WhlPageType.Detail)) {
    fromPageType = WhlPageType.Detail;
  }

  // Check to-path
  if (toPathSegments.includes(WhlPageType.Bookmarks)) {
    toPageType = WhlPageType.Bookmarks;
  } else if (toPathSegments.includes(WhlPageType.Overview)) {
    toPageType = WhlPageType.Overview;
  } else if (toPathSegments.includes(WhlPageType.List)) {
    toPageType = WhlPageType.List;
  } else if (toPathSegments.includes(WhlPageType.Detail)) {
    toPageType = WhlPageType.Detail;
  }

  if (fromPageType === toPageType) return;

  if (fromPageType) {
    const previousWhlPageTypes = JSON.parse(
      sessionStorage.getItem('previousWhlPageTypes') || '[]'
    ) as WhlPageType[];
    const lastPageType = previousWhlPageTypes[previousWhlPageTypes.length - 1];
    if (lastPageType !== fromPageType && fromPageType !== WhlPageType.Detail) {
      previousWhlPageTypes.push(fromPageType);
      sessionStorage.setItem(
        'previousWhlPageTypes',
        JSON.stringify(previousWhlPageTypes)
      );
    }
  }

  if (toPageType) {
    const currentWhlPageType = toPageType;
    sessionStorage.setItem('currentWhlPageType', currentWhlPageType);
  }
});
